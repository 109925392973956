import React, {useEffect, useRef, useState} from 'react'
import Button from "../../atoms/Button";
import Card, {CardHeaderActions, CardTitle, Separator, SimpleCardHeader} from "../../atoms/Card";
import SearchInput from "../../atoms/SearchInput";
import {useFeatureFlag} from "../../context/FeatureFlagsContext";
import AddDataToFileButtonWithModal from "./addDataToFile/AddDataToFileButtonWithModal";
import OrdersPagination from "./components/OrdersPagination";
import OrderTableLoadingState from "../../loadingPageStates/OrderTableLoadingState";
import {QueryRenderer, graphql} from 'react-relay'
import OrdersTable, {ORDERS_TABLE_HEADER_HEIGHT} from "../../organisms/Orders/OrdersTable";
import DownloadOrdersModal from "./DownloadOrdersModal";
//This is not forgotten, it's just disabled for now
// import AttachOCModal from "./attachOCModal/AttachOCModal";
import OrderConfirmationUploadWizardModal from "./OrderConfirmationUploadWizardModal";
import {uploadOrderConfirmationBatch} from "../../api/orderConfirmations";
import {packOrderConfirmationFiles} from "../../commons/http";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {OrderTableQuery} from "./__generated__/OrderTableQuery.graphql";
import styles from "./OrderTable.module.scss"
import Dropdown from "../../atoms/Dropdown";
import DownloadOrdersDirectlyModal from "./downloadDirectlyModal/DownloadOrdersDirectlyModal";
import {usePagination} from "../../commons/pagination";
import OrderViaBarcodesModal from "./addViaBarcodes/OrderViaBarcodesModal";
import {BrandUserContextType, RetailerUserContextType} from "../../context/CurrentUserContext";

const BASE_PER_PAGE = 10;
const ORDERS_CARD_HEADER_HEIGHT = 80;
const ORDER_ROW_MIN_HEIGHT = 82;

const query = graphql`
  query OrderTableQuery (
    $search: String,
    $first: Int,
    $last: Int,
    $before: String,
    $after: String,
  ) {
    listOrdersAtOnce(first: $first, after: $after, last: $last, before: $before, search: $search) {
      ...OrdersPagination_pagination
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...on OrderNode {
            orderNumber
          }
          ... on ManuallyUploadedOrderNode {
            orderNumbers
          }
          __isNode: __typename
          ...OrderRow_order
        }
      }
    }
  }
`

type OrderTableProps = {
  isRetailer: boolean,
  scopeUser: BrandUserContextType["brand"] | RetailerUserContextType["retailer"] | undefined,
  environment: RelayModernEnvironment,
  perPage: number | null,
  setPerPage: (val: number) => void,
  onOrderClick: (id: string) => void,
}

export default function OrderTable({isRetailer, scopeUser, environment, perPage, setPerPage, onOrderClick}: OrderTableProps) {
  const scopeId = scopeUser?.id;
  const {pagination, getExtraStateKey, setExtraStateKey} = usePagination();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [orderRowHeight, setOrderRowHeight] = useState(ORDER_ROW_MIN_HEIGHT);
  const [isOrderConfirmationWizardOpen, setIsOrderConfirmationWizardOpen] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [isAddDataToFileModalOpen, setIsAddDataToFileModalOpen] = useState(false)
  const [isAddOrderViaBarcodesModalOpen, setIsAddOrderViaBarcodesModalOpen] = useState(false)
  const [isDownloadDirectlyModalOpen, setIsDownloadDirectlyModalOpen] = useState(false);
  //This is not forgotten, it's just disabled for now
  // const [isAttachOCOpen, setIsAttachOCOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const isDataCompletionFeatureEnabled = useFeatureFlag('products.file_completion').active;
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [pendingBatchId, setPendingBatchId] = useState(null);
  const search = getExtraStateKey("search");
  const hasPaginationData = !!(pagination.first || pagination.last);

  const toggle = () => {
    setIsOrderConfirmationWizardOpen(false);
    setUploadError(null);
    setIsLoading(false);
  }

  const modalsDropdownOptions = [
    {
      label: "Add orders",
      value: "Add orders",
      icon: <i className={"fa-light fa-fw fa-plus"}/>,
      onSelect: () => setIsOrderConfirmationWizardOpen(true),
      dataTestId: "orders-dropdown-item-add-orders"
    },
    ...(isRetailer ? [{
      label: "Add an order via barcodes",
      value: "Add an order via barcodes",
      icon: <i className={"fa-light fa-fw fa-plus"}/>,
      onSelect: () => setIsAddOrderViaBarcodesModalOpen(true),
      dataTestId: "orders-dropdown-item-add-order-via-barcodes"
    }] : []),
    ...(isDataCompletionFeatureEnabled ? [{
      label: "Add data to a file",
      value: "Add data to a file",
      icon: <i className={"fa-light fa-fw fa-plus"}/>,
      onSelect: () => setIsAddDataToFileModalOpen(true),
      dataTestId: "orders-dropdown-item-add-data-to-order-file"
    }] : []),
    ...(!isRetailer ? [{
      label: "Download data directly",
      value: "Download data directly",
      icon: <i className={"fa-light fa-fw fa-arrow-down-to-line"}/>,
      onSelect: () => setIsDownloadDirectlyModalOpen(true),
      dataTestId: "orders-dropdown-item-download-data-directly"
    }] : []),
  ]

  const tableHeight = cardRef?.current ? (cardRef.current.clientHeight - ORDERS_CARD_HEADER_HEIGHT - ORDERS_TABLE_HEADER_HEIGHT) : null;
  useEffect(() => {
    if (tableHeight) {
      const numberOfRows = Math.floor(tableHeight / ORDER_ROW_MIN_HEIGHT);
      const finalNumberOfRows = numberOfRows >= BASE_PER_PAGE ? numberOfRows : BASE_PER_PAGE;
      const rowHeight = tableHeight / finalNumberOfRows;
      setOrderRowHeight(rowHeight >= ORDER_ROW_MIN_HEIGHT ? rowHeight : ORDER_ROW_MIN_HEIGHT)
      setPerPage(finalNumberOfRows);
    }
  }, [tableHeight])

  return <Card border
               fillSpace
               ref={cardRef}>
    {hasPaginationData ? <QueryRenderer<OrderTableQuery>
      environment={environment}
      query={query}
      variables={{
        search: getExtraStateKey("search"),
        ...pagination
      }}
      render={({error, props, retry}) => {
        if (error) {
          return <div>Error</div>
        } else if (props) {
          return <>
            <SimpleCardHeader style={{borderBottom: "0", height: ORDERS_CARD_HEADER_HEIGHT}}>
              <CardTitle>
                <div className="d-flex align-items-center">
                  <h1 className={styles.title}>Orders</h1>
                  {(props.listOrdersAtOnce?.totalCount !== 0 || !!search) && <>
                    <Separator className={'mx-3'}/>
                    <SearchInput placeholder={'Search'}
                                 delay={500}
                                 value={search}
                                 onChange={e => setExtraStateKey("search", e.target.value)}
                                 data-testid={"orders-input-search"}/>
                  </>}
                </div>
              </CardTitle>
              <CardHeaderActions>
                {/*This is not forgotten, it's just disabled for now*/}
                {/*<Button onClick={() => setIsAttachOCOpen(true)} icon={'fas fa-paperclip'}>*/}
                {/*  Attach OC*/}
                {/*</Button>*/}
                <Dropdown currentValue={{label: "More actions..."}}
                          color={"secondary"}
                          icon={<i className={'fa-regular fa-fw fa-ellipsis-vertical'}/>}
                          options={modalsDropdownOptions}
                          onSelectOption={(option) => {
                            //@ts-ignore
                            option.onSelect();
                          }}
                          caret={false}
                          menuWidth={'11.75rem'}
                          data-testid={"orders-dropdown-more-actions"}/>
                <Button onClick={() => isRetailer ? setIsDownloadDirectlyModalOpen(true) : setIsAddOrderViaBarcodesModalOpen(true)}
                        color={"primary"}
                        icon={<i className={`fa-regular fa-fw ${isRetailer ? "fa-arrow-down-to-line" : "fa-plus"}`}/>}
                        className={styles.primaryActionButton}
                        data-testid={isRetailer ? "orders-button-download-data-directly" : "orders-button-add-order-via-barcodes"}>
                  {isRetailer ? "Download data directly" : "Add an order via barcodes"}
                </Button>
                {(props.listOrdersAtOnce?.totalCount !== 0 || !!search) && <>
                  <Separator/>
                  <OrdersPagination pagination={props.listOrdersAtOnce}/>
                </>}
              </CardHeaderActions>
            </SimpleCardHeader>
            <OrdersTable isRetailer={isRetailer}
                         environment={environment}
                         search={search}
                         resetFilters={() => setExtraStateKey("search", "")}
                         props={props}
                         retry={retry}
                         onOrderClick={onOrderClick}
                         perPage={perPage}
                         orderRowHeight={orderRowHeight}
                         setIsDownloadModalOpen={setIsDownloadModalOpen}
                         selectedOrders={selectedOrders}//@ts-ignore
                         setSelectedOrders={setSelectedOrders}/>
            {isDownloadModalOpen && <DownloadOrdersModal
              isOpen={isDownloadModalOpen}
              toggle={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
              orders={selectedOrders}
              environment={environment}
            />}
            {/*This is not forgotten, it's just disabled for now*/}
            {/*<AttachOCModal*/}
            {/*  isOpen={isAttachOCOpen}*/}
            {/*  toggle={() => setIsAttachOCOpen(!isAttachOCOpen)}*/}
            {/*  ddts={props.listDistributeDataTemplates}*/}
            {/*  scopeId={scopeId}*/}
            {/*  isRetailer={isRetailer}*/}
            {/*  environment={environment}*/}
            {/*/>*/}
            {isOrderConfirmationWizardOpen && <OrderConfirmationUploadWizardModal
              environment={environment}
              isOpen={isOrderConfirmationWizardOpen}
              isLoading={isLoading}
              pendingBatchId={pendingBatchId}
              onFinished={(orderConfirmationBatch) => {
                setIsLoading(false);
                if (orderConfirmationBatch?.error) {
                  setUploadError(orderConfirmationBatch.error);
                } else {
                  // close the modal only for successful runs
                  toggle();
                  retry && retry()
                }
                setPendingBatchId(null);
              }}
              toggle={toggle}
              error={uploadError}
              onUploadFiles={(newFiles, ddt) => {
                setIsLoading(true);
                uploadOrderConfirmationBatch({
                  data:
                    {
                      async: 'true',
                      ...(isRetailer ? {retailer: scopeId} : {brand: scopeId}),
                      ddt,
                    },
                  files: packOrderConfirmationFiles(newFiles),
                  //@ts-ignore //ToDo: delete when orderConfirmations.js gets converted to TS
                  onUploadFinished: (orderConfirmBatchId) => setPendingBatchId(orderConfirmBatchId),
                  //@ts-ignore //ToDo: delete when orderConfirmations.js gets converted to TS
                  onError: (err) => {
                    setIsLoading(false);
                    setUploadError(err);
                  }
                })
              }}
            />}
            {isAddDataToFileModalOpen && <AddDataToFileButtonWithModal
              environment={environment}
              isOpen={isAddDataToFileModalOpen}
              setIsOpen={setIsAddDataToFileModalOpen}
            />}
            {isDownloadDirectlyModalOpen && <DownloadOrdersDirectlyModal
              isOpen={isDownloadDirectlyModalOpen}
              isRetailer={isRetailer}
              toggle={() => setIsDownloadDirectlyModalOpen(false)}
              environment={environment}
            />}
            {isAddOrderViaBarcodesModalOpen && scopeUser && <OrderViaBarcodesModal
              isOpen={isAddOrderViaBarcodesModalOpen}
              toggle={() => setIsAddOrderViaBarcodesModalOpen(false)}
              isRetailer={isRetailer}
              scopeUser={scopeUser}
              environment={environment}
            />}
          </>
        } else {
          return <OrderTableLoadingState/>
        }
      }}
    /> : <OrderTableLoadingState/>}
  </Card>
}
