import React, {useState} from 'react'
import GrayModal, {ButtonSpec, GrayModalProps} from "../../../dataDisplay/GrayModal";
import DownloadOrdersDirectlyInfo from "./DownloadOrdersDirectlyInfo";
import DownloadOrdersDirectlyBarcodesInput from "./DownloadOrdersDirectlyBarcodesInput";
import DownloadOrdersDirectlySelectDdt, {selectedDdtType} from "./DownloadOrdersDirectlySelectDdt";
import {ErrorAlert} from "../../../commons/errors";
import DownloadOrdersDirectlyLoading from "./DownloadOrdersDirectlyLoading";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import DownloadOrdersDirectlyDownload from "./DownloadOrdersDirectlyDownload";
import LoadingContent from "../../products/DistributeDataModalContentComponents/LoadingContent";
import GenerateDirectDownload from "../../../mutations/GenerateDirectDownloadMutation";
import DownloadOrdersDirectlyCheckBarcodesWarning from "./DownloadOrdersDirectlyCheckBarcodesWarning";

function DownloadOrdersDirectlyError({error} : {error: string}) {
  return <div style={{ width: '34rem', minWidth: '100%', padding: "4rem 2rem"}}>
    <ErrorAlert error={error} />
  </div>
}

const isBarcode = (value: string) => {
  return value.length === 13;
}

const STEP = {
  INFO: -1,
  BARCODES_INPUT: 0,
  SELECT_DDT: 1,
  ERROR: 2,
  MISSING_BARCODES_WARNING: 3,
  LOADING: 4,
  DOWNLOAD_ORDER: 5
}

const steps = [
  {stepText: "Add style numbers & barcodes"},
  {stepText: "Select output"}
]

type ModalDataType = {
  title?: string,
  primaryHeaderButton?: ButtonSpec,
  secondaryHeaderButton?: ButtonSpec,
  steps?: GrayModalProps['steps'],
  stepIndex?: number,
  bodyContent: JSX.Element,
  toggle: () => void
}

type DownloadOrdersDirectlyModalProps = {
  isOpen: boolean,
  isRetailer: boolean,
  toggle: () => void,
  environment: RelayModernEnvironment
}

function DownloadOrdersDirectlyModal({isOpen, isRetailer, toggle, environment}: DownloadOrdersDirectlyModalProps) {
  const [step, setStep] = useState(STEP.INFO)
  const [barcodes, setBarcodes] = useState("")
  const [selectedDdt, setSelectedDdt] = useState<selectedDdtType | null>(null)
  const [downloadImages, setDownloadImages] = useState(false);
  const [outputProcessId, setOutputProcessId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [resultId, setResultId] = useState<string | null>(null);

  const onError = (error: string) => {
    setError(error);
    setStep(STEP.ERROR);
  }

  const onDownload = () => {
    if (!selectedDdt?.value) {
      onError("No template found");
      return;
    }

    setStep(STEP.LOADING);
    GenerateDirectDownload(
      environment,
      {
        ddt: selectedDdt.value,
        withImages: downloadImages,
        data: barcodes.split(/[ \n]+/)
      },
      (result) => {
        if(result?.generateDirectDownload?.outputProcess?.id) {
          setOutputProcessId(result.generateDirectDownload.outputProcess.id)
        } else {
          onError("Something went wrong...")
        }
      },
      (err) => {
        onError(err[0].message);
      }
    )
  }

  const modalData: ModalDataType = (() => {
    const defaultModalData: ModalDataType = {
      title: "Download data directly",
      secondaryHeaderButton: {
        text: "Cancel",
        onClick: toggle,
        dataTestId: "orders-button-download-content-directly-cancel"
      },
      bodyContent: <ErrorAlert error={"Invalid step"}/>,
      toggle: toggle
    };

    if (step === STEP.INFO) {
      return {
        ...defaultModalData,
        primaryHeaderButton: {
          text: "Continue",
          onClick: () => setStep(STEP.BARCODES_INPUT),
          dataTestId: "orders-button-download-content-directly-continue"
        },
        bodyContent: <DownloadOrdersDirectlyInfo/>
      }
    } else if (step === STEP.BARCODES_INPUT) {
      return {
        ...defaultModalData,
        primaryHeaderButton: {
          text: "Next",
          onClick: () => setStep(STEP.SELECT_DDT),
          isDisabled: !barcodes,
          dataTestId: "orders-button-download-content-directly-continue"
        },
        steps: steps,
        bodyContent: <DownloadOrdersDirectlyBarcodesInput
          barcodes={barcodes}
          setBarcodes={setBarcodes}/>
      }
    } else if (step === STEP.SELECT_DDT) {
      return {
        ...defaultModalData,
        primaryHeaderButton: {
          text: "Next",
          onClick: () => setStep(STEP.MISSING_BARCODES_WARNING),
          isDisabled: !selectedDdt,
          dataTestId: "orders-button-download-content-directly-download"
        },
        steps: steps,
        bodyContent: <DownloadOrdersDirectlySelectDdt
          selectedDdt={selectedDdt}
          setSelectedDdt={setSelectedDdt}
          downloadImages={downloadImages}
          setDownloadImages={setDownloadImages}
          environment={environment}
          onError={onError}/>
      }
    } else if (step === STEP.ERROR) {
      return {
        ...defaultModalData,
        bodyContent: <DownloadOrdersDirectlyError error={error || "Something went wrong."}/>
      }
    } else if (step === STEP.MISSING_BARCODES_WARNING) {
      const barcodesList = barcodes
        .split("\n")
        .map(b => b.replaceAll(" ", ""))
        .filter(b => {
          return !!b && isBarcode(b)
        })

      return {
        bodyContent: <DownloadOrdersDirectlyCheckBarcodesWarning
          barcodesList={barcodesList}
          isRetailer={isRetailer}
          onDownload={onDownload}
          onError={onError}
          environment={environment}
          toggle={toggle}/>,
        toggle: () => {}
      }
    } else if (step === STEP.LOADING) {
      return {
        bodyContent: <LoadingContent
          environment={environment}
          outputProcessId={outputProcessId}
          onFinished={output => {
            setOutputProcessId(null);
            setResultId(output.id);
            setStep(STEP.DOWNLOAD_ORDER)
          }}
          onFailed={err => {
            setStep(STEP.ERROR);
            setOutputProcessId(null);
            setError(err);
          }}>
          <DownloadOrdersDirectlyLoading toggle={toggle} hasDownloadImages={downloadImages} environment={environment}
                                         outputProcessId={outputProcessId} outputType={selectedDdt?.outputType}/>
        </LoadingContent>,
        toggle: () => {}
      }
    } else if (step === STEP.DOWNLOAD_ORDER) {
      if (!resultId) {
        onError("Error fetching result");
        return {
          bodyContent: <></>,
          toggle: toggle
        }
      }

      return {
        bodyContent: <DownloadOrdersDirectlyDownload
          resultId={resultId}
          environment={environment}
          onError={onError}
          toggle={toggle}/>,
        toggle: () => {}
      }
    }

    return defaultModalData;
  })()

  return <GrayModal
    isOpen={isOpen}
    toggle={modalData.toggle}
    style={{ minWidth: "fit-content" }}
    title={modalData.title}
    primaryHeaderButton={modalData.primaryHeaderButton}
    secondaryHeaderButton={modalData.secondaryHeaderButton}
    steps={modalData.steps}
    stepsIndex={step}
    bodyContent={modalData.bodyContent}
  />
}

export default DownloadOrdersDirectlyModal;
