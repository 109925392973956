import Checkbox from "../../../../atoms/Checkbox";
import Button from "../../../../atoms/Button";
import React from "react";
import {NewTokenType, RowConfigType} from "../types";
import {ProductFieldsProvider} from "../utilities";
import styles from "./GenerateWithAiModalContent.module.scss";
import Tooltip from "../../../../atoms/Tooltip";
import addClassNames from "../../../../classNameUtils";
import {cloneObject} from "../../../../objectUtils";
import {formatTokenDisplayFromPath} from "../../backendAdapter";

type GenerateWithAiModalContentProps = {
  aiGeneratedConfig: Record<string, string>,
  config: {
    outputField: string,
    config: RowConfigType
  }[],
  selectedTokens: Record<string, NewTokenType[]>,
  setSelectedTokens: (val: Record<string, NewTokenType[]>) => void,
  productFieldsProvider: ProductFieldsProvider,
}

export default function GenerateWithAiModalContent({
                                                     config,
                                                     aiGeneratedConfig,
                                                     selectedTokens,
                                                     setSelectedTokens,
                                                     productFieldsProvider
                                                   }: GenerateWithAiModalContentProps) {

  const onSelectToken = (outputField: string, token: NewTokenType) => {
    const selectedTokensCopy = cloneObject(selectedTokens);
    if (selectedTokensCopy.hasOwnProperty(outputField)) {
      delete selectedTokensCopy[outputField];
    } else {
      selectedTokensCopy[outputField] = [token];
    }
    setSelectedTokens(selectedTokensCopy);
  };

  return <div className={styles.container}>
    <div className={styles.tooltipContainer}>
      <p className={styles.info}>Select the AI generated configuration fields that match your data requirements</p>
      <Tooltip text={"Selected configurations will override the exising data requirements."}
               mode={'light'}
               placement={"bottom"}>
        <i className={`fa-light fa-circle-question ${styles.tooltip}`}/>
      </Tooltip>
    </div>
    {
      config.map((row, i) => {
        const {outputField} = row;
        const tokenProductField = productFieldsProvider.getByPath(aiGeneratedConfig[outputField]);

        const aiToken = tokenProductField && {
          ...tokenProductField,
          name: formatTokenDisplayFromPath(tokenProductField.name, tokenProductField.path),
          type: tokenProductField.type || [],
        };

        const isChecked = selectedTokens.hasOwnProperty(outputField);
        const isDisabled = !aiToken;

        return <div key={i} className={styles.rowContainer}>
          <Checkbox checked={isChecked}
                    disabled={isDisabled}
                    onChange={() => {
                      if (aiToken) {
                        onSelectToken(outputField, {
                          name: aiToken.name,
                          path: aiToken.path,
                          type: aiToken.type,
                        })
                      }
                    }}/>
          <div className={"d-flex flex-column"}>
            <div className={styles.headerContainer}>
              <div className={styles.index}>{i + 1}</div>
              <p className={styles.title}>{outputField}</p>
            </div>
            <div className={styles.dataContainer}>
              <p className={addClassNames([
                {className: styles.subtitle, condition: true},
                {className: styles.subtitleDisabled, condition: isDisabled},
              ])}>
                Data requirements
              </p>
              <Button disabled={isDisabled}>
                <i className="fas fa-bars mr-2"/>
                {aiToken?.name || "Unknown"}
              </Button>
            </div>
          </div>
        </div>
      })
    }
  </div>
}
