import React, {useEffect} from 'react';
import {ourToast} from "../../../../atoms/Toast";
import {HttpService} from "../../../../commons/http";
import {useState} from "react";
import GrayModal from "../../../../dataDisplay/GrayModal";
import {useWindowDimensions} from "../../../../commons/hooks";
import {NewTokenType, RowConfigType} from "../types";
import {ProductFieldsProvider} from "../utilities";
import GenerateWithAiModalContent from "./GenerateWithAiModalContent";
import LoadingModalContent from "../../../../molecules/loading/LoadingModalContent";
import loadingStyles from "../../../../molecules/loading/LoadingModalContent.module.scss";
import {ErrorAlert} from "../../../../commons/errors";
import Pill from "../../../../atoms/Pill";

enum STEPS {
  LOADING,
  SELECTING_FIELDS,
}

type CreateAutoMapConfigFieldsRequestType = {
  mapping_config_id: string
}

type CreateAutoMapConfigFieldsResponseType = {
  data?: {
    mapping_config_id: string,
    mapped_config_fields?: Record<string, string>
  }
  status: string
}

type GenerateWithAiModalProps = {
  configId: string | null,
  config: {
    outputField: string,
    config: RowConfigType
  }[],
  onReplaceFields: (newFieldsData: Record<string, NewTokenType[]>) => void,
  productFieldsProvider: ProductFieldsProvider,
  isOpen: boolean,
  toggle: () => void
}

export default function GenerateWithAiModal({
                                              configId,
                                              config,
                                              onReplaceFields,
                                              productFieldsProvider,
                                              isOpen,
                                              toggle
                                            }: GenerateWithAiModalProps) {
  const [step, setStep] = useState(STEPS.LOADING);
  const [aiGeneratedConfig, setAiGeneratedConfig] = useState<Record<string, string>>({});
  const [selectedTokens, setSelectedTokens] = useState<Record<string, NewTokenType[]>>({});
  const modalWidth = useWindowDimensions().width * 0.75;

  const generateMappingConfig = () => {
    if (!configId) {
      ourToast("error", "Something went wrong!", "Failed to get configId");
      toggle();
      return;
    }

    HttpService.POST<CreateAutoMapConfigFieldsRequestType, CreateAutoMapConfigFieldsResponseType>(
      "/api/v1/automapconfigfields/", {
        mapping_config_id: configId
      })
      .then(res => {
        if (!!res?.data?.mapped_config_fields) {
          setAiGeneratedConfig(res.data.mapped_config_fields);
          setStep(STEPS.SELECTING_FIELDS);
        } else {
          ourToast("error", "Failed to generate data requirements");
          toggle();
        }
      })
      .catch(err => {
        ourToast("error", "Failed to generate data requirements", err);
        toggle();
      })
  }

  useEffect(() => {
    generateMappingConfig();
  }, [configId]);

  const onAccept = () => {
    onReplaceFields(selectedTokens);
    toggle();
  }

  const modalData = (() => {
    const defaultData = {
      title: "Generate data requirements",
      primaryHeaderButton: undefined,
      secondaryHeaderButton: {
        text: "Cancel",
        onClick: toggle
      },
      style: undefined,
      bodyContent: <ErrorAlert error={"Invalid step"}/>
    }

    if (step === STEPS.LOADING) {
      return {
        ...defaultData,
        title: undefined,
        secondaryHeaderButton: undefined,
        bodyContent: <LoadingModalContent
          subtitle={"Generating data requirements."}
          actionButton={
            <Pill onClick={toggle} className={`mt-3 ${loadingStyles.button}`}>
              <p className={`m-0 ${loadingStyles.buttonText}`}>Close</p>
            </Pill>
          }/>
      }
    } else if (step === STEPS.SELECTING_FIELDS) {
      return {
        ...defaultData,
        primaryHeaderButton: {
          text: "Accept",
          onClick: onAccept,
          isDisabled: Object.keys(selectedTokens).length === 0
        },
        style: {
          minWidth: '950px',
          width: `${modalWidth}px`,
          maxWidth: '1500px',
        },
        bodyContent: <GenerateWithAiModalContent
          config={config}
          aiGeneratedConfig={aiGeneratedConfig}
          selectedTokens={selectedTokens}
          setSelectedTokens={setSelectedTokens}
          productFieldsProvider={productFieldsProvider}/>
      }
    } else {
      return defaultData;
    }
  })();

  return <GrayModal
    isOpen={isOpen}
    title={modalData.title}
    primaryHeaderButton={modalData.primaryHeaderButton}
    secondaryHeaderButton={modalData.secondaryHeaderButton}
    style={modalData.style}
    toggle={toggle}
    bodyContent={modalData.bodyContent}/>
}
