import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
// Containers
import {DefaultLayout} from './containers';
// Import Main styles for this application
import './scss/style.scss'

// import { renderRoutes } from 'react-router-config';
import './ui-kit/src/assets/fontawesome-pro/css/all.css';
import {EnvFetcher} from "./ui-kit/src/commons/http";

// Pages
import {Login} from './views/Pages';
import IntegrationsLayout from "./views/Pages/integrations/IntegrationsLayout";
import Register from "./views/Pages/Register";
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Pages/ForgotPassword/ResetPassword";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <EnvFetcher onLoad={(envConfig) => {
        if (!envConfig.sentry_dsn) {
          return;
        }
        Sentry.init({
          dsn: envConfig.sentry_dsn,
          environment: envConfig.environment_name,
          release: envConfig.release_name,
          integrations: [new BrowserTracing()]
        })
      }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/login/:newAccount" component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route exact path="/password-reset" component={ResetPassword}/>
            <Route path="/integrations" component={IntegrationsLayout}/>
            <Route path="/" component={DefaultLayout}/>
          </Switch>
        </BrowserRouter>
      </EnvFetcher>
    </QueryClientProvider>
  );
}
