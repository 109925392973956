import Checkbox from "../../../../atoms/Checkbox";
import Button from "../../../../atoms/Button";
import React, {useState} from "react";
import {GlossaryEntry} from "../types";
import styles from "./GenerateWithAiModalContent.module.scss";
import Tooltip from "../../../../atoms/Tooltip";
import addClassNames from "../../../../classNameUtils";
import Dropdown from "../../../../atoms/Dropdown";
import {pluralizeByCount} from "../../../../stringUtils";

type RowDataType = {
  clientValue: string,
  display_value: string,
  isSelected: boolean,
}

type GenerateWithAiCustomizedValuesContentProps = {
  toggle: () => void,
  aiGeneratedSuggestions: {
    display_value: string,
    final_value: string,
    mapped_values: string[]
  }[],
  acceptSuggestedValues: (values: GlossaryEntry[]) => void,
}

export default function GenerateWithAiCustomizedValuesContent({
                                                                toggle,
                                                                aiGeneratedSuggestions,
                                                                acceptSuggestedValues
                                                              }: GenerateWithAiCustomizedValuesContentProps) {
  const [rowsData, setRowsData] = useState<Record<string, RowDataType>>(
    aiGeneratedSuggestions.reduce(
      (rows: Record<string, RowDataType>, suggestion) => {
        if (suggestion.mapped_values.length > 0) {
          rows[suggestion.final_value] = {
            clientValue: suggestion.mapped_values[0],
            display_value: suggestion.display_value,
            isSelected: false
          };
        }
        return rows;
      }, {})
  );
  const selectedCount = Object.values(rowsData).filter(row => row.isSelected).length;
  const areAllRowsSelected = aiGeneratedSuggestions.length > 0 && selectedCount === aiGeneratedSuggestions.length;

  function onSelect(rawValue: string) {
    setRowsData(prev => ({
      ...prev,
      [rawValue]: {
        ...prev[rawValue],
        isSelected: !prev[rawValue].isSelected,
      }
    }))
  }

  function onSelectAll() {
    setRowsData(prev => {
      return Object.keys(prev).reduce((rows: typeof rowsData, key) => {
        rows[key] = {...prev[key], isSelected: !areAllRowsSelected};
        return rows;
      }, {});
    });
  }

  function onApplySelected() {
    acceptSuggestedValues(
      Object.entries(rowsData)
        .filter(([_, data]) => data.isSelected)
        .map(([rawValue, data], i) => ({
          type: 'raw',
          raw: rawValue,
          value: data.clientValue,
          display: data.display_value
        }))
    );
    toggle();
  }

  return <div className={styles.container}>
    <div className={styles.tooltipContainer}>
      <p className={styles.info}>Select the AI generated mappings that match your data requirements</p>
      <Tooltip text={"Selected configurations will override the exising data requirements."}
               mode={'light'}
               placement={"bottom"}>
        <i className={`fa-light fa-circle-question ${styles.tooltip}`}/>
      </Tooltip>
    </div>
    <div className={'p-3'}>
      <Checkbox checked={areAllRowsSelected}
                indeterminate={!!selectedCount && !areAllRowsSelected}
                onChange={onSelectAll}
                disabled={aiGeneratedSuggestions.length === 0}
                label={!selectedCount ? "Select all" : `${selectedCount} selected`}/>
    </div>
    {Object.entries(rowsData).map(([rawValue, data], i) => {
      const availableOptions = aiGeneratedSuggestions
        .find(suggestion => suggestion.final_value === rawValue)?.mapped_values
        .map(val => ({
          label: val,
          value: val,
        }));

      return <div key={rawValue} className={styles.rowContainer}>
        <Checkbox
          checked={data.isSelected}
          onChange={() => {
            onSelect(rawValue);
          }}
          label={
            <div className={"d-flex flex-row align-items-center"}>
              <p className={"m-0"}>
                  <span className={addClassNames([
                    {className: styles.title, condition: true},
                    {className: 'mr-2', condition: true},
                  ])}>
                    {data.display_value}
                  </span>

                <span className={addClassNames([
                  {className: styles.subtitle, condition: true},
                  {className: 'mr-2', condition: true},
                ])}>
                    will be matched to
                  </span>
              </p>
            </div>
          }
        />
        {!!availableOptions && availableOptions.length > 1 ? <Dropdown
          currentValue={{label: data.clientValue}}
          options={availableOptions}
          onSelectOption={(op) => {
            if (op.value != null) {
              setRowsData((prev) => ({
                ...prev,
                [rawValue]: {
                  ...prev[rawValue],
                  clientValue: op.value as string,
                }
              }))
            }
          }}
        /> : (
          <Button>{data.clientValue}</Button>
        )}
      </div>
    })}

    <Button
      disabled={selectedCount === 0}
      className={"ml-auto"}
      onClick={onApplySelected}
    >
      {selectedCount === 0 ? 'Select suggestions' : `Apply selected ${selectedCount} ${pluralizeByCount('value', selectedCount)}`}
    </Button>
  </div>
}
