import React, {useEffect, useState} from "react";
import styles from './TargetFieldEditor.module.scss';
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Rule from "./Rule";
import {cloneObject} from "../../../../objectUtils";
import FieldGlossaryEditor from "./FieldGlossaryEditor";
import AddRuleDropdown from "./dropdowns/AddRuleDropdown";
import {BrandRule} from "./token/BrandRule";
import RULES from "./rules";
import RuleMapper from "./RuleMapper";
import addClassNames from "../../../../classNameUtils";
import DataPointsHeader from "./DataPointsHeader";
import ExamplePreviewBadge from "../example/ExamplePreviewBadge";
import UniqueValueRepresentationsDropdown from "./token/UniqueValueRepresentationsDropdown";
import GenerateDescriptionFieldEditor from "./GenerateDescriptionFieldEditor";
import ImageIndexDropdown from "./token/ImageIndexDropdown";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {GeneralTokenRule, isNumericFormatRule, ProductPathTokenType, TokenType} from "../types";
import {ProductFieldsProvider} from "../utilities";

function isInlineRule(rule: GeneralTokenRule) {
  return !isBlockRule(rule);
}

function isBlockRule(rule: GeneralTokenRule) {
  return RULES.getRuleInfo(rule.type).hasOwnProperty('blockRule');
}

type TokenRulesProps = {
  token: ProductPathTokenType,
  fieldDisplay: string | null,
  productFieldsProvider: ProductFieldsProvider,
  onChange: (newVal: TokenType) => void,
  fieldType: string[] | null,
  isRetailer: boolean
};

function TokenRules({token, fieldDisplay, productFieldsProvider, onChange, fieldType, isRetailer}: TokenRulesProps) {
  let [isExpanded, setIsExpanded] = useState(true)

  let caretClasses = addClassNames([
    {className: "fa-solid ml-auto", condition: true},
    {className: "fa-caret-down", condition: !isExpanded},
    {className: "fa-caret-up", condition: isExpanded},
  ])

  function createRuleElement(rule: GeneralTokenRule, index: number) {
    return <Rule
      fieldDisplay={fieldDisplay}
      {...rule}
      productFieldsProvider={productFieldsProvider}
      onChange={newRuleContent => {
        let newValue = cloneObject(token);
        newValue.rules[index] = newRuleContent;
        onChange(newValue);
      }}
      onRemove={() => {
        let newValue = cloneObject(token);
        newValue.rules.splice(index, 1);
        onChange(newValue);
      }}
    />
  }

  return <>
    <div className={styles.rules}>
      <div className={'d-flex align-items-center w-100'}>
        <span className={styles.generalRulesText}>
          General rules
        </span>
        {isExpanded ? <AddRuleDropdown
            tokenValueType={fieldType}
            onChange={onChange}
            token={token}
            allowBrandRules={isRetailer}
            tooltip={'Add General Rules'}
          /> :
          <span style={{marginLeft: "-0.5rem"}}>...</span>}
        <i className={caretClasses}
           style={{fontSize: "1rem", padding: '0.5rem', margin: '-0.5rem', cursor: 'pointer'}}
           onClick={() => setIsExpanded(!isExpanded)}/>
      </div>
      {isExpanded && token.rules?.length > 0 && <div className={"w-100"} style={{marginTop: "-0.5rem"}}>
        <hr style={{marginLeft: "-1.5rem", marginRight: "-1.5rem"}} className={"mt-0"}/>
        <RuleMapper rules={token.rules} createRuleElement={createRuleElement}/>
      </div>}
    </div>
  </>
}

export function validateRules(rules: GeneralTokenRule[], setInvalidityMessage: (val: string | null) => void) {
  if (rules.filter(isInlineRule).filter(
    rule => isNumericFormatRule(rule) && rule.numeric_format === null
  ).length > 0) {
    setInvalidityMessage("It seems like you have an unselected numeric format rule. Select a format or remove it before going back")
  } else {
    setInvalidityMessage(null)
  }
}

function getUniqueValueType(type: string[] | null) {
  if (!type) {
    return null;
  }
  for (let t of type) {
    if (t.startsWith('$$')) {
      return t
    }
  }
  return null;
}

type TargetFieldEditorProps = {
  value: ProductPathTokenType,
  fieldDisplay: string | null,
  fieldType: string[] | null,
  rowIndex: number,
  configId: string | null,
  sourceRowDisplay: string,
  productFieldsProvider: ProductFieldsProvider,
  brands: { id: string, name: string }[],
  isRetailer: boolean,
  userBrand: { id: string, name: string } | null,
  onChange: (newVal: TokenType) => void,
  onBack: () => void,
  exampleProductData: { brandName: string } | null,
  environment: RelayModernEnvironment
};

function TargetFieldEditor({
                             value, fieldDisplay, fieldType, configId, rowIndex, sourceRowDisplay,
                             productFieldsProvider, brands = [],
                             isRetailer, userBrand,
                             onChange, onBack,
                             exampleProductData,
                             environment
                           }: TargetFieldEditorProps) {
  const [invalidityMessage, setInvalidityMessage] = useState<string | null>(null)
  const isGeneratedDescription = value.path === '_gen_description'
  const isImage = value.path?.startsWith("_images") || false;

  const uniqueValueType = getUniqueValueType(fieldType);
  const representation = value.uv_representation || null;

  useEffect(() => {
    if (value.rules) {
      validateRules(value.rules, setInvalidityMessage)
    }
  }, [value])

  return <>
    <DataPointsHeader invalidityMessage={invalidityMessage} onBack={onBack}/>
    <ExamplePreviewBadge index={rowIndex}
                         sourceRowDisplay={sourceRowDisplay}
                         isConfigureImageNaming={false}
                         field={null}
                         page={fieldDisplay}
                         productData={exampleProductData}
                         showInfo={'brand'}/>

    {isGeneratedDescription ?
      <GenerateDescriptionFieldEditor value={value} onChange={onChange}/> :
      <>
        <Row className={styles.titleContainer}>
          <Col className={'p-0'}>
            <h5>{fieldDisplay}</h5>
          </Col>
          <Col className={'d-flex justify-content-end'} style={{position: 'relative'}}>
            {uniqueValueType && <UniqueValueRepresentationsDropdown
              type={uniqueValueType}
              environment={environment}
              representation={representation}
              setRepresentation={(r) => {
                if (r) {
                  onChange({...value, uv_representation: r})
                }
              }}
            />}
            {isImage && <ImageIndexDropdown imageIndex={value?.image_index || 1} className={'ml-2'}
                                            setImageIndex={index => onChange({...value, image_index: index})}/>}
          </Col>
        </Row>
        <div className={styles.body}>
          <TokenRules token={value}
                      onChange={onChange}
                      productFieldsProvider={productFieldsProvider}
                      fieldDisplay={fieldDisplay}
                      fieldType={fieldType}
                      isRetailer={isRetailer}
          />

          <div className={"mr-3"}>
            {value.brandRules && value.brandRules.map((brandRule, i) => {
              if (!isRetailer && (userBrand?.id !== brandRule.brand)) {
                return null;
              }

              if (!isRetailer && brands.length === 0) {
                brands = brandRule.brand ? [{id: brandRule.brand, name: userBrand?.name || 'Current brand'}] : [];
              }

              return <BrandRule
                key={i}
                brands={brands}
                selectedBrand={brandRule.brand}
                currentFieldDisplay={fieldDisplay}
                productFieldsProvider={productFieldsProvider}
                disabled={(!isRetailer && userBrand !== null)}
                rules={brandRule.rules}
                exceptions={brandRule.exceptions}
                onChange={(newBrandRule) => {
                  let newVal = cloneObject(value);
                  newVal.brandRules[i] = newBrandRule;
                  onChange(newVal);
                }}
                onRemove={() => {
                  let newVal = cloneObject(value)
                  newVal.brandRules = newVal.brandRules.filter((x, j) => i !== j);
                  onChange(newVal);
                }}
              />
            })}
          </div>

          <div className={"mt-2 mr-3"}>
            <FieldGlossaryEditor type={fieldType}
                                 isOrientation={value.path?.split(".")[value.path.split(".").length - 1] === "orientation"}
                                 glossaryValues={value.glossary}
                                 tokenPath={value.path}
                                 configFieldName={sourceRowDisplay}
                                 configId={configId}
                                 onRemove={(index) => {
                                   value.glossary.splice(index, 1)
                                   onChange(value)
                                 }}
                                 onAddGlossaryValues={(values) => {
                                   onChange({
                                     ...value,
                                     glossary: [...(value.glossary || []), ...values]
                                   })
                                 }}
                                 onAddGlossaryValue={() => onChange(
                                   {
                                     ...value,
                                     glossary: [...(value.glossary || []), {
                                       type: 'raw',
                                       raw: '',
                                       value: ''
                                     }]
                                   })}
                                 onGlossaryValuesChange={(index, newGlossaryValues) => {
                                   onChange({
                                     ...value,
                                     glossary: newGlossaryValues
                                   })
                                 }}/>
          </div>
        </div>
      </>}
  </>
}

export default TargetFieldEditor;
