import GrayModal from "../../../../dataDisplay/GrayModal";
import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {HttpService} from "../../../../commons/http";
import {Textarea} from "../../../../atoms/Input";
import Button from "../../../../atoms/Button";
import {ourToast} from "../../../../atoms/Toast";
import LoadingModalContent from "../../../../molecules/loading/LoadingModalContent";
import GenerateWithAiCustomizedValuesContent from "../generateWithAiModal/GenerateWithAiCustomizedValuesContent";
import {GlossaryEntry} from "../types";


type StandardizedValuesManagerModalProps = {
  tokenPath: string,
  configId: string,
  configFieldName: string,
  isOpen: boolean,
  toggle: () => void,
  currentGlossaryValues: GlossaryEntry[],
  onAddGlossaryValues: (values: GlossaryEntry[]) => void,
}

type ResponseType = {
  status: string,
  substatus: string,
  data: {
    values: string[]
  },
  detail?: string,
}

type GetSuggestionsResponseType = {
  status: string,
  substatus: string,
  data: {
    suggestions: {
      display_value: string,
      final_value: string,
      mapped_values: string[]
    }[]
  },
  detail?: string,
}


type SavePayloadType = {
  mapping_config_id: string,
  config_field: string,
  values: string[]
}

type GetSuggestionsPayloadType = {
  mapping_config_id: string,
  config_field: string,
  token_path: string
}


function MainEditStep({path, configField, configId, onGenerateWithAi}: {
  path: string,
  configField: string,
  configId: string,
  onGenerateWithAi: () => void,
}) {
  const {data, isLoading, isError, error} = useQuery<ResponseType, Error>({
    queryKey: ['StandardizedValuesManagerModal', 'MainEditStep', path],
    queryFn: () => {
      return HttpService.GET<ResponseType>(
        `/api/v1/output/standardizedvalues/`,
        {
          mapping_config_id: configId,
          config_field: configField
        }
      )
    }
  })
  const [values, setValues] = useState<string>('');
  const [requestsAreLoading, setRequestsAreLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setValues(data.data.values.join('\n'))
    } else {
      setValues('');
    }
  }, [data])

  async function onSave() {
    try {
      setRequestsAreLoading(true);
      const resp = await HttpService.POST<SavePayloadType, ResponseType>(
        `/api/v1/output/standardizedvalues/`,
        {mapping_config_id: configId, config_field: configField, values: values.split('\n')}
      )
      setRequestsAreLoading(false);
      if (resp.status !== 'success') {
        ourToast('error', `Unable to save: ${resp.detail}`)
        return;
      }
      ourToast('success', 'Saved!')
    } catch (e) {
      setRequestsAreLoading(false);
      ourToast('error', `Unable to save: ${e}`)
    }
  }

  if (isLoading) {
    return <div className="w-100 mx-auto"><LoadingModalContent title={'Loading standardized values'}/></div>
  } else if (isError) {
    return <div>{error?.message || 'Something went wrong...'}</div>
  } else if (!data) {
    return <div>Something went wrong, loading completed but we have no data...</div>
  }

  return <div className="p-3">
    <Textarea rows={20} value={values} onChange={e => setValues(e.target.value)}></Textarea>
    <div className="row">
      <div className="col d-flex">
        <Button disabled={requestsAreLoading}
                onClick={onSave}>
          Save
        </Button>
        <Button onClick={onGenerateWithAi}
                className={'ml-3'}
                disabled={requestsAreLoading}
                icon={"fa-light fa-sparkles"}>
          Generate with AI
        </Button>
      </div>
    </div>
  </div>;
}

function SuggestionsWithAiStep({toggle, path, configId, configField, currentGlossaryValues, acceptSuggestedValues}: {
  toggle: () => void,
  path: string,
  configId: string,
  configField: string,
  currentGlossaryValues: GlossaryEntry[],
  acceptSuggestedValues: (values: GlossaryEntry[]) => void,
}) {
  const [data, setData] = useState<GetSuggestionsResponseType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {

        let resp = await HttpService.POST<GetSuggestionsPayloadType, GetSuggestionsResponseType>(
          `/api/v1/output/standardizedvaluessuggestions/`,
          {mapping_config_id: configId, config_field: configField, token_path: path}
        )
        setData(resp);
        setIsLoading(false);
        setError(null);
      } catch (e) {
        setIsLoading(false);
        setError(e as Error)
      }
    }

    fetchData();
  }, [path, configId, configField])

  if (isLoading) {
    return <div className="d-flex justify-content-center">
      <LoadingModalContent
        title={'Generating suggestions with AI'}
        subtitle={'Mapping as many values as possible automatically for you...'}
      />
    </div>
  }

  if (error) {
    return <div className="p-3">Something went wrong: {error.message}</div>
  }

  if (!data) {
    return <div className="p-3">Something went wrong: suggestions failed to generate</div>
  }

  const filteredSuggestions = data.data.suggestions
    .filter(suggestion => {
      return !currentGlossaryValues.find(glossaryVal => glossaryVal.raw === suggestion.final_value)
    })

  return <GenerateWithAiCustomizedValuesContent
    toggle={toggle}
    acceptSuggestedValues={acceptSuggestedValues}
    aiGeneratedSuggestions={filteredSuggestions}
  />
}


type ModalBodyProps = {
  toggle: () => void,
  path: string,
  configField: string,
  configId: string,
  currentGlossaryValues: GlossaryEntry[],
  acceptSuggestedValues: (values: GlossaryEntry[]) => void
}

function ModalBody({
                     toggle,
                     path,
                     configField,
                     configId,
                     currentGlossaryValues,
                     acceptSuggestedValues
                   }: ModalBodyProps) {
  const [step, setStep] = useState<'edit' | 'suggestions'>('edit');

  if (step === 'edit') {
    return <MainEditStep
      configId={configId}
      path={path}
      configField={configField}
      onGenerateWithAi={() => setStep('suggestions')}/>
  } else if (step === 'suggestions') {
    return <SuggestionsWithAiStep
      toggle={toggle}
      configField={configField}
      configId={configId}
      path={path}
      currentGlossaryValues={currentGlossaryValues}
      acceptSuggestedValues={acceptSuggestedValues}
    />
  }

  return <div>Unknown error...</div>
}


export default function StandardizedValuesManagerModal({
                                                         tokenPath,
                                                         configFieldName,
                                                         configId,
                                                         currentGlossaryValues,
                                                         onAddGlossaryValues,
                                                         isOpen,
                                                         toggle
                                                       }: StandardizedValuesManagerModalProps) {

  return <GrayModal
    size={'lg'}
    isOpen={isOpen}
    toggle={toggle}
    title={'Manage standardized values'}
    secondaryHeaderButton={{
      text: 'Cancel',
      onClick: toggle,
    }}
    bodyContent={<ModalBody toggle={toggle}
                            configField={configFieldName}
                            configId={configId}
                            path={tokenPath}
                            currentGlossaryValues={currentGlossaryValues}
                            acceptSuggestedValues={onAddGlossaryValues}/>}
  />
}
