/**
 * @example
 * isBase64("SGVsbG8gd29ybGQ="); // true
 * isBase64("Hello world"); // false
 */
export const isBase64 = (str: string) => {
  if (str.length === 0) {
    return false;
  }

  try {
    return btoa(atob(str)) === str;
  } catch (e) {
    return false;
  }
}
