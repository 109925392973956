import {graphql} from "react-relay";
import React from "react";
import Loading from "../../../atoms/Loading";
import {PollingQueryRenderer} from "../../../general/graphql/PollingQueryRenderer";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {UploadingFilesStepQuery} from "./__generated__/UploadingFilesStepQuery.graphql";
import {OrderConfirmationBatchType} from "./types";

const query = graphql`
  query UploadingFilesStepQuery($id: ID!) {
    orderConfirmationBatch(id: $id) {
      id
      state
      error
    }
  }
`

function isFinishedOrFailed(orderConfirmationBatch: OrderConfirmationBatchType | null) {
  if (!orderConfirmationBatch) {
    return false;
  }

  return ['finished', 'failed'].includes(orderConfirmationBatch.state.toLowerCase());
}

type UploadingFilesStepProps = {
  pendingBatchId: string | null,
  onFinished: (orderConfirmationBatch: OrderConfirmationBatchType | null) => void,
  environment: RelayModernEnvironment,
}

export function UploadingFilesStep({pendingBatchId, onFinished, environment}: UploadingFilesStepProps) {
  if (!pendingBatchId) {
    return <div>
      <p className="text-dark">
        Processing files
      </p>
      <Loading/>
    </div>
  }

  return <PollingQueryRenderer<UploadingFilesStepQuery>
    environment={environment}
    query={query}
    variables={{id: pendingBatchId}}
    delay={1500}
    onError={err => {
      onFinished(null);
    }}
    onData={data => {
      if (data && isFinishedOrFailed(data.orderConfirmationBatch)) {
        onFinished(data.orderConfirmationBatch)
      }
    }}
  >
    <div>
      <p className="text-dark">
        Processing files
      </p>
      <Loading/>
    </div>
  </PollingQueryRenderer>
}
