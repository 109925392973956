import React, {useState} from "react";
import Row from "reactstrap/lib/Row";
import Button from "../../../../atoms/Button";
import styles from "./FieldGlossaryEditor.module.scss"
import GlossaryRow from "./token/GlossaryRow";
import {GlossaryEntry} from "../types";
import StandardizedValuesManagerModal from "./StandardizedValuesManagerModal";

type FieldGlossaryEditorProps = {
  type: string[] | null,
  tokenPath: string | null,
  configFieldName: string | null,
  configId: string | null
  isOrientation: boolean,
  glossaryValues: GlossaryEntry[],
  onAddGlossaryValue: () => void,
  onAddGlossaryValues: (newValues: GlossaryEntry[]) => void,
  onGlossaryValuesChange: (index: number, value: GlossaryEntry[]) => void,
  onRemove: (index: number) => void
}

function FieldGlossaryEditor({
                               type,
                               tokenPath,
                               configId,
                               configFieldName,
                               isOrientation,
                               glossaryValues,
                               onGlossaryValuesChange,
                               onAddGlossaryValue,
                               onAddGlossaryValues,
                               onRemove,
                             }: FieldGlossaryEditorProps) {
  const [isOpen, setIsOpen] = useState(false);

  if (!glossaryValues) {
    glossaryValues = [] as GlossaryEntry[];
  }

  let orientationOptions = [
    {label: "Front", value: "front"},
    {label: "Back", value: "back"},
    {label: "Side", value: "side"},
    {label: "Other", value: "other"},
  ]

  if (glossaryValues) {
    orientationOptions = orientationOptions.filter(option => {
      let isPresent = false
      for (let glossaryValue of glossaryValues) {
        if (option.value === glossaryValue.raw) {
          isPresent = true
        }
      }
      return !isPresent
    })
  }

  return <div className={styles.container}>
    <Row className={'d-flex align-items-center'} noGutters>
      Customized Value
      <div>
        <Button className={'mx-3'}
                data-testid={'add-glossary'}
                onClick={onAddGlossaryValue}
                disabled={isOrientation && glossaryValues.length === 4}
                icon={'fa-plus'}/>
      </div>

      {tokenPath && configFieldName && configId && <>
        <Button onClick={() => setIsOpen(true)} className={'ml-auto'}>
          Manage standardized values
        </Button>
        <StandardizedValuesManagerModal configId={configId}
                                        configFieldName={configFieldName}
                                        tokenPath={tokenPath}
                                        isOpen={isOpen}
                                        currentGlossaryValues={glossaryValues}
                                        onAddGlossaryValues={onAddGlossaryValues}
                                        toggle={() => setIsOpen(!isOpen)}/>
      </>}
    </Row>
    {glossaryValues.length > 0 &&
      <hr style={{marginLeft: "-1.5rem", marginRight: "-1.5rem", marginTop: "1.5rem"}} className={"mb-0"}/>
    }
    {glossaryValues.map(({raw, value}, index) => {
      return <GlossaryRow key={index}
                          orientationOptions={orientationOptions}
                          isOrientation={isOrientation}
                          index={index}
                          type={type}
                          glossaryValues={glossaryValues}
                          onGlossaryValuesChange={onGlossaryValuesChange}
                          onRemove={onRemove}
      />
    })}
  </div>
}

export default FieldGlossaryEditor;
