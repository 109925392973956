import React, {useState} from "react";
import {createFragmentContainer, QueryRenderer, graphql} from "react-relay";
import GrayModal from "../../dataDisplay/GrayModal";
import ChooseDDTStep from "./orderConfirmationSteps/ChooseDDTStep";
import InvalidFileIntermediateStep from "./orderConfirmationSteps/InvalidFileIntermediateStep";
import OrderConformationFileUploadStep from "./orderConfirmationSteps/OrderConfirmationFileUploadStep";
import {UploadingFilesStep} from "./orderConfirmationSteps/UploadingFilesStep";
import {ErrorAlert} from "../../commons/errors";
import Loading from "../../atoms/Loading";
import {OrderConfirmationUploadWizardModalQuery} from "./__generated__/OrderConfirmationUploadWizardModalQuery.graphql";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {NormalizedDdtType, OrderConfirmationBatchType} from "./orderConfirmationSteps/types";

type DdtType = {
  id: string,
  name: string,
  isGlossaryMapped: boolean,
  orderConfirmationConfigs: {
    edges: {
      node: {
        id: string,
        name: string
      }
    }[]
  }
}

type DdtsListType = {
  edges: {
    node: DdtType
  }[]
}

const query = graphql`
  query OrderConfirmationUploadWizardModalQuery {
    listDistributeDataTemplates(isActive: true) {
      edges {
        node {
          id
          name
          isGlossaryMapped
          orderConfirmationConfigs {
            edges {
              node {
                name
              }
            }
          }
        }
      }
      edges {
        node {
          id
          name
          isGlossaryMapped
          orderConfirmationConfigs {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`

enum STEPS {
  SELECT_DDT,
  SELECT_FILES
}

function getOrderConfirmationConfigs(ddts: DdtsListType, currentDdt: string | null) {
  if (!currentDdt) {
    return []
  }
  return ddts.edges.filter(e => e.node.id === currentDdt)[0].node.orderConfirmationConfigs.edges.map(e => e.node);
}

function getDdts(ddts: DdtsListType): NormalizedDdtType[] {

  return ddts.edges.map(e => {
    return {
      id: e.node.id,
      name: e.node.name,
      orderConfirmations: e.node.orderConfirmationConfigs.edges.map(edge => edge.node.name),
      disabled: !e.node.isGlossaryMapped
    }
  }).filter(node => node.orderConfirmations.length > 0);
}

type ModalWrapperProps = {
  isOpen: boolean,
  toggle: () => void,
  children: JSX.Element
}

function ModalWrapper({isOpen, toggle, children}: ModalWrapperProps) {
  return <GrayModal size={'lg'}
                    isOpen={isOpen}
                    toggle={toggle}
                    title={"Upload order confirmations"}
                    secondaryHeaderButton={{text: 'Cancel', onClick: toggle, dataTestId: "orders-button-add-oc-cancel"}}
                    bodyContent={<div className={'p-3'}>{children}</div>}/>
}

type OrderConfirmationUploadWizardModalProps = {
  isOpen: boolean,
  toggle: () => void,
  environment: RelayModernEnvironment,
  pendingBatchId: string | null,
  onFinished: (orderConfirmationBatch: OrderConfirmationBatchType | null) => void,
  isLoading: boolean,
  error: string | null,
  onUploadFiles: (newFiles: Record<string, File[]>, ddt: string | null) => void,
  allowRawBarcodeMode?: boolean,
}

function OrderConfirmationUploadWizardModal({
                                              isOpen,
                                              toggle,
                                              environment,
                                              pendingBatchId,
                                              onFinished,
                                              isLoading,
                                              error,
                                              onUploadFiles,
                                              allowRawBarcodeMode = true,
                                            }: OrderConfirmationUploadWizardModalProps) {
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.SELECT_DDT);
  const [currentDdt, setCurrentDdt] = useState<string | null>(null);

  // reset the wizard state when dismissing the modal
  const clearAndToggle = () => {
    setCurrentStep(STEPS.SELECT_DDT);
    setCurrentDdt(null);
    toggle();
  }

  const getContent = (ddts: DdtsListType) => {
    if (error) {
      return <InvalidFileIntermediateStep
        error={error}
      />
    } else if (isLoading) {
      return <UploadingFilesStep
        pendingBatchId={pendingBatchId}
        onFinished={onFinished}
        environment={environment}
      />
    } else if (currentStep === STEPS.SELECT_DDT) {
      return <ChooseDDTStep
        ddts={getDdts(ddts)}
        onDdtChange={({value}) => {
          setCurrentDdt(value);
          setCurrentStep(STEPS.SELECT_FILES);
        }}
      />
    } else if (currentStep === STEPS.SELECT_FILES) {
      return <OrderConformationFileUploadStep
        allowRawBarcodeMode={allowRawBarcodeMode}
        toggle={clearAndToggle}
        configs={getOrderConfirmationConfigs(ddts, currentDdt)}
        submitFiles={(files: Record<string, File[]>) => {
          onUploadFiles(files, currentDdt);
        }}
      />
    }
    return <ErrorAlert error={"Something went wrong"}/>;
  }

  return <ModalWrapper toggle={clearAndToggle} isOpen={isOpen}>
    <QueryRenderer<OrderConfirmationUploadWizardModalQuery>
      environment={environment}
      query={query}
      variables={{}}
      render={({props, error}) => {
        if (error) {
          return <ErrorAlert error={error}/>
        }
        if (props && props.listDistributeDataTemplates && props.listDistributeDataTemplates.edges) {
          return getContent(props.listDistributeDataTemplates as DdtsListType)
        }
        return <Loading/>
      }}
    />
  </ModalWrapper>
}

export default createFragmentContainer(
  OrderConfirmationUploadWizardModal,
  {
    ddts: graphql`
      fragment OrderConfirmationUploadWizardModal_ddts on DistributeDataTemplateNodeConnection {
        edges {
          node {
            id
            name
            isGlossaryMapped
            orderConfirmationConfigs {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    `
  }
);
